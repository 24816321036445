import Vue from 'vue';
import App from './app.vue';
import Router from './router';
import Store from './store';

const fire = require('./firebaseConfig.js')

import { firestorePlugin } from 'vuefire'
Vue.use(firestorePlugin)

Vue.config.productionTip = false;

import regeneratorRuntime from "regenerator-runtime";

new Vue({
    render: createElement => createElement(App),
    router: Router,
    store: Store
}).$mount('#app');
