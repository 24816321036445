<template>
   <div>
      <div class="Page-navbar">
         <div class="Page-navbarWrap Navbar">
            <div class="Navbar-brand">Skillspace</div>

            <ul class="Navbar-nav" v-if="!loading.isLoading">
               <!-- <li v-show="hasValidUser"><router-link to="/plans">Plans</router-link></li> -->
               <li v-show="hasValidUser && userHasAccess"><router-link to="/">Classes</router-link></li>
               <li v-show="hasValidUser"><a @click.prevent="signOut">Logout</a></li>
            </ul>
         </div>
      </div>

      <router-view v-if="!loading.isLoading"></router-view>

      <loading-app></loading-app>
   </div>
</template>

<script>
   import Vue from "vue"
   import {mapState} from 'vuex';
   const fire = require('./firebaseConfig.js')

   import LoadingApp from './components/loading-app.vue'

   export default Vue.extend({
      components: {
         LoadingApp
      },

      data() {
         return {
            isLoading: true
         }
      },

      computed: {
         ...mapState({
            loading: state => state.loading,
            user: state => state.user,
            sessions: state => state.sessions,
         }),

         hasValidUser() {
            return Object.keys(this.user).length !== 0;
         },

         userHasAccess() {
            if(this.hasValidUser) {
               return this.user.access.sessions.status !== 'trial-expired'
            }
         },

         userSessionsAccess() {
            if(this.hasValidUser) {
               return this.user.access.sessions.status;
            }
         },
      },

      watch: {
         userSessionsAccess: {
            immediate: true,
            handler() {
               if (typeof this.userSessionsAccess !== 'undefined') {
                  if (this.userSessionsAccess == 'trial') {
                     // block user access
                     this.checkTrialValidity();
                  }

                  if (this.userSessionsAccess == 'trial-expired') {
                     console.log('trial expired', this.$router.currentRoute.name)
                     if (this.sessions.length !== 0) {
                        this.unbindSessions();
                     }

                     // block user access
                     if (this.$router.currentRoute.name !== 'Plans' && this.$router.currentRoute.name !== 'Plans Success') {
                        this.$router.push({name: 'Plans'});
                     }
                     // this.$store.dispatch('updateLoadingState', false);
                  }

                  if (this.userSessionsAccess == 'paid') {
                     if (this.$router.currentRoute.name == 'Plans') {
                        this.$router.push({name: 'Sessions'});
                     }
                     // this.$store.dispatch('updateLoadingState', false);
                  }
               }
            }
         }
      },

      created() {
         var self = this;

         fire.auth.onAuthStateChanged(user => {
            if (user) {
               // console.log('onAuthStateChanged', user.metadata.creationTime, user.metadata.lastSignInTime, user)
               // if (user.metadata.creationTime !== user.metadata.lastSignInTime) {
                  // not a new user
                  this.$store.dispatch('updateLoadingState', true);
                  self.initUser(user);
               // }
            } else {
               // no user is signed in
               if (this.$router.currentRoute.name !== 'Login') {
                  self.$router.push({ name: 'Login' });
               }
            }
         });

         if (!this.hasValidUser) {
            this.$store.dispatch('updateLoadingState', false);
         }
      },

      mounted() {
         // this.initSessionsBind();
      },

      methods: {
         initUser(userData) {
            this.$store.dispatch('getUserDetails', userData);

            if (this.sessions.length == 0) {
               this.initSessionsBind();
            }

            if (this.$router.currentRoute.name == 'Login' || this.$router.currentRoute.name == 'Sign Up') {
               this.$router.push({name: 'Sessions'});
            }
         },

         async initSessionsBind() {
            // if (Object.keys(this.user).length !== 0) {
               try {
                  await this.$store.dispatch('bindSessions')
               } catch (e) {
                  console.error(e)
               }
            // }
         },

         async unbindSessions() {
            try {
               await this.$store.dispatch('unbindSessions')
            } catch (e) {
               console.error(e)
            }
         },

         checkTrialValidity() {
            var today = new Date()
            var created = new Date(this.user.created);

            // To calculate the time difference of two dates
            var differenceInTime = today.getTime() - created.getTime();

            // To calculate the no. of days between two dates
            var differenceInDays = differenceInTime / (1000 * 3600 * 24);

            if (differenceInDays > 0) { // set to 0 to remove trial without removing logic
               // console.log('trial run out')
               this.$store.dispatch('updateUserAccess', {type: 'sessions', status: 'trial-expired', userId: this.user.uid});
               this.$router.push({ name: 'Plans'})
            } else {
               this.$store.dispatch('updateLoadingState', false);
            }
         },

         signOut(e) {
            fire.auth.signOut();

            this.$router.push({
               name: "Login"
            });
         }
      }
   });
</script>

<style>


</style>
