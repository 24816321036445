<template>
<section class="Page-panel">
   <div class="Grid">
      <div class="Grid-column" v-show="!paymentSuccess">
         <div class="LoginCard mt++ center">
            <p class="">Processing payment...</p>
         </div>
      </div>

      <div class="Grid-column" v-show="paymentSuccess">
         <h1 class="center">Congratulations</h1>

         <div class="LoginCard mt++ center">
            <p class="">You now have full access to our online Classes.</p>
            <button class="button mt++" @click="$router.push('/classes')">Continue to Class</button>
         </div>
      </div>
   </div>
</section>
</template>

<script>
   import Vue from 'vue'
   import {mapState} from 'vuex';
   const fire = require('./../../firebaseConfig.js')

   export default {
      data() {
         return {
            sessionId: '',
            payments: []
         }
      },

      watch: {
         payment: {
            immediate: true,
            handler() {
               this.$bind('payments', fire.eventsCollection
                   .where('id', '==', this.$route.query.session_id)
               )
            }
         },
         paymentSuccess() {
            if(this.paymentSuccess) {
               console.log('success')
               this.updateUserSubscription();
            }
         }
      },

      computed: {
         ...mapState({
            user: state => state.user,
         }),

         paymentSuccess() {
            if (typeof this.payments[0] !== 'undefined') {
               console.log('pay', this.payments[0])
               return this.payments[0].sessionId == this.user.access.sessions.subscription.sessionId;
            } else {
               return false;
            }
         }
      },

      mounted() {},

      beforeDestroy() {
         // TODO: unbind
         // this.$unbind('events')
      },

      // firestore: {
      //    // events: fire.eventsCollection,
      // },

      methods: {
         updateUserSubscription(sessionId) {
            this.$store.dispatch('updateUserAccess', {type: 'sessions', status: 'paid', userId: this.user.uid});

            fire.usersCollection.doc(this.user.uid)
               .update({
                  // "access.sessions.status": 'paid',
                  "access.sessions.subscription.customer": this.payments[0].customer,
                  "access.sessions.subscription.id": this.payments[0].subscription
               })
               .then(() => {
                  console.log('Subscription updated')
               });
         }
      }
   }
</script>
