<template>
   <div>
      <div class="question" v-for="(question, index) in questions">
         <div class="">{{question.first}} {{question.operator}} {{question.second}} =</div>
         <input type="text" @keyup.enter="check(index)" v-model="question.answer">
         <div class="">{{question.isCorrect}}</div>
      </div>

      <a href="#" class="" @click.prevent="gen">Generate</a>
   </div>
</template>

<script type="text/javascript">
   export default {
      props: {},

      data() {
         return {
            count: 5,
            operators: ['+', '-', '/', '*'],
            questions: []
         };
      },

      methods: {
         check(index) {
            // this.isCorrect = 1*8 == this.answer;
            // console.log('huh', eval(this.questions[index].all))
            // this.questions[index].isCorrect = eval(this.questions[index].all) == this.questions[index].answer;
            this.questions[index].isCorrect = eval(this.questions[index].first + this.questions[index].operator + this.questions[index].second) == this.questions[index].answer;
         },

         gen() {
            this.questions = [];

            for (var i = 0; i < this.count; i++) {
               var question = {
                  first: Math.floor(Math.random() * 13),
                  second: Math.floor(Math.random() * 13),
                  operator: this.operators[Math.floor(Math.random() * this.operators.length)],
                  answer: null,
                  isCorrect: null,
               }

               this.questions.push(question)
            }

            // console.log('a', this.questions)
         }
      }
   }
</script>

<style>
   .question {
      display: flex;
   }

   .question + .question {
      margin-top: 6px;
   }

   .question > * + * {
      margin-left: 6px;
   }
</style>
