<template>
   <div v-show="isActive" class="Tabs-content">
      <slot></slot>
   </div>
</template>

<script>
   export default {
      props: {
         name: {
            required: true
         },
         selected: {
            default: false
         }
      },

      data() {
         return {
            isActive: false
         };
      },

      computed: {},

      mounted() {
         this.isActive = this.selected;
      }
   }
</script>
