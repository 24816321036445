<template>
   <section class="Page-panel">
      <div class="Grid">
         <div class="Grid-column">
            <h1 class="center">Sign Up</h1>
            <!-- <p class="mw-24 middle center mt+">You will get a free 7-day trial when you sign up. At the end of this trial you will be asked to start a weekly recurring payment ....</p> -->
            <div class="mw-38 middle center mt+">
               <p class=""><strong>Terms of Use</strong></p>
               <p>If you or the student emails work to me you agree that this may be shared as part of a follow-up lesson or to celebrate achievements. If you do not wish the work to be shared, please state this clearly in the email.</p>
               <p>Any comments made during lessons will not be visible to other students but may be shared with other students in order to make suggestions and develop learning. All comments should be polite and respectful with no bad language used. We reserve the right to suspend your account should this not be the case.</p>
               <p>Skillspace have no responsibility for the activities your child completes at home. Please ensure children are properly supervised where necessary. Skillspace is not responsible for any accidents which may arise from taking part in any of the lessons or subsequent activities.</p>
            </div>

            <div class="LoginCard mt++">
               <form @submit.prevent="createNewFireUser">
                  <div class="Form-field">
                     <label for="" class="Form-label">Student's first name</label>
                     <input type="text" class="Form-input" v-model="newUser.name.first.value" required>
                  </div>

                  <div class="Form-field">
                     <label for="" class="Form-label">Student's last name</label>
                     <input type="text" class="Form-input" v-model="newUser.name.last.value" required>
                  </div>

                  <div class="Form-field">
                     <label for="" class="Form-label">Email address</label>
                     <input type="email" class="Form-input" v-model="newUser.email.value" required>
                     <div class="" v-show="!newUser.email.isValid">{{ newUser.email.errorMessage }}</div>
                  </div>

                  <div class="Form-field">
                     <label for="" class="Form-label">Password</label>
                     <input type="password" class="Form-input" ref="userPassword" required>
                     <div class="" v-show="!newUser.password.isValid">{{ newUser.password.errorMessage }}</div>
                  </div>

                  <div class="Form-field LoginCard-submitWrap">
                     <input type="submit" class="Form-submit" value="Sign Up">
                  </div>
               </form>
            </div>

            <div class="mt+ center">
               Already have an account? <router-link :to="{ name: 'Login' }">Sign in</router-link>
            </div>
         </div>
      </div>
   </section>
</template>

<script>
   import Vue from 'vue';
   import { mapState } from 'vuex'
   const fire = require('./../firebaseConfig.js')

   export default Vue.extend({
      data() {
         return {
            newUser: {
               name: {
                  first: {
                     value: '',
                     isValid: true,
                     errorMessage: ''
                  },

                  last: {
                     value: '',
                     isValid: true,
                     errorMessage: ''
                  }
               },
               email: {
                  value: '',
                  isValid: true,
                  errorMessage: ''
               },
               password:{
                  isValid: true,
                  errorMessage: ''
               }
            }
         }
      },

      computed: {
         // ...mapState(['isUniqueTeamName']),
         user() {
            return this.$store.state.user;
         }
      },

      created() {
         fire.auth.onAuthStateChanged(user => {
            if (user && user.metadata.creationTime === user.metadata.lastSignInTime) {
               // if new user
               this.signUpUser(user);
            }
         });
      },

      methods: {
         createNewFireUser() {
            var self = this;

            this.newUser.email.isValid = true;
            this.newUser.password.isValid = true;

            return fire.auth.createUserWithEmailAndPassword(this.newUser.email.value, this.$refs.userPassword.value)
               .catch(function(error) {
                 var errorCode = error.code;
                 var errorMessage = error.message;

                 if (errorCode == 'auth/email-already-in-use') {
                    self.newUser.email.isValid = false;
                    self.newUser.email.errorMessage = 'Sorry, this email address is already in use';
                 }
                 else if (errorCode == 'auth/invalid-email') {
                    self.newUser.email.isValid = false;
                    self.newUser.email.errorMessage = 'Please enter a valid email address';
                 }
                 else if (errorCode == 'auth/weak-password') {
                    self.newUser.password.isValid = false;
                    self.newUser.password.errorMessage = 'Password should be at least 6 characters';
                 }
                 return;
               });
         },

         signUpUser(user) {
            this.$store.dispatch('createNewUser', {
               uid: user.uid,
               name: {
                  first: this.newUser.name.first.value,
                  last: this.newUser.name.last.value
               },
               email: user.email,
               // created: user.metadata.creationTime,
               access: {
                  sessions: {
                     status: 'trial-expired' // was trial
                  }
               }
             });

            // TODO: Check team created
            this.$router.push({ name: 'Sessions' });
         },
      }
   });
</script>
