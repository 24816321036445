<template>
   <section class="Page-contain">
      <section class="Page-panel">
         <div class="Grid">
            <div class="Grid-column">
               <h1>Classes Timetable</h1>

               <!-- <button @click.prevent="testTrigger">test</button> -->
            </div>
         </div>

         <div class="mt++" v-if="sessionsTimetable.length > 0">
            <div class="Grid Grid-stack" :class="{'Grid-splashPaint': isToday(day.scheduledFor)}" v-if="sessionsTimetable.length > 0" v-for="(day, index) in sessionsTimetable">
               <div class="Grid-column">
                  <div class="typeHeading-2" v-if="isToday(day.scheduledFor)">Today</div>
                  {{formatDate(day.scheduledFor)}}
               </div>

               <div class="Grid-column s-1/5--tab mt+" v-for="(session, index) in day.sessions">
                  <a href="#" class="Card" :class="'paint-'+session.subject.toLowerCase()" @click.prevent="goToSession(session.id)">
                     <div>{{formatTime(session.scheduledFor)}}</div>
                     <div class="typeHeading-3">{{ session.subject }}</div>
                  </a>
               </div>
            </div>
         </div>
      </section>
   </div>
</template>

<script>
   import Vue from "vue";
   import { mapState } from 'vuex';
   import axios from 'axios';
   // import dateMixin from './../mixins/dateMixin.js';

   export default Vue.extend({
      beforeRouteEnter (to, from, next) {
         next(vm => {
            if (typeof vm.user.access.sessions.status !== 'undefined' && vm.user.access.sessions.status == 'trial-expired') {
               next({name: 'Plans'})
            }
            else next();
         })
      },

      data() {
         return {
            sessionsTimetable: []
         }
      },

      computed: {
         ...mapState({
            loading: state => state.loading,
            user: state => state.user,
            sessions: state => state.sessions,
         })
      },

      // mixins: [dateMixin],

      watch: {
         sessions: {
            immediate: true,
            handler() {
               this.formatTimetableData();
            }
         },

         // user() {
         //    console.log('user', this.user)
         // }
      },

      methods: {
         testTrigger() {
            axios.post('https://us-central1-skillspace-app.cloudfunctions.net/MuxCreateLiveStream')
               .then((response) => {
                  console.log('Axios complete', response)
               })
               .catch(error => {
                  console.log('err', error)
               });
         },

         formatTimetableData() {
            this.sessionsTimetable = [];

            this.sessions.forEach((item, i) => {
               if (i == 0 || (i > 0 && !this.matchDate(item.scheduledFor, this.sessions[i - 1].scheduledFor))) {
                  this.sessionsTimetable.push({'scheduledFor': item.scheduledFor, 'sessions': []})
               }

               this.sessionsTimetable[this.sessionsTimetable.length - 1].sessions.push(item)
            });
         },

         goToSession(sessionId) {
            this.$router.push({ name: 'Session', params: { id: sessionId } });
         },

         matchDate(date1, date2) {
            if (typeof date1 !== 'undefined' && typeof date2 !== 'undefined') {
               var d1 = date1.toDate();
               var d2 = date2.toDate();
               d1 = new Date(d1.toDateString());
               d2 = new Date(d2.toDateString());
               return d1 <= d2;
            }
         },

         isToday(timestamp) {
            console.log('dd', timestamp)
            if (typeof timestamp !== 'undefined') {
               const date = timestamp.toDate();
               const today = new Date();
               return date.getDate() == today.getDate() &&
                  date.getMonth() == today.getMonth() &&
                  date.getFullYear() == today.getFullYear()
            }
         },

         formatDate(timestamp) {
            if (typeof timestamp !== 'undefined') {
               var date = timestamp.toDate();
               date = new Date(date);

               // Months array
               var months_arr = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

               // Month
               var month = months_arr[date.getMonth()];

               // Day
               var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
               var day = days[date.getDay()];
               var dayDate = date.getDate();

               // var year = date.getFullYear();

               // Display date time in day date month format
               return day+ ' '+dayDate+' '+month;
            }
         },

         formatTime(timestamp) {
            if (typeof timestamp !== 'undefined') {
               var date = timestamp.toDate();
               date = new Date(date);

               // Hours
               var hours = date.getHours();

               // Minutes
               var minutes = "0" + date.getMinutes();

               // Display date time in MM-dd-yyyy h:m:s format
               return hours + ':' + minutes.substr(-2);
            }
         }
      }
   });
</script>
