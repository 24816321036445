<template>
   <section class="" v-if="typeof session !== 'undefined'">
      <div class="Page-panel Page-panel--class">
         <div class="Grid">
            <div class="Grid-column">
               <div class="Page-actionBar">
                  <div class="Page-actionBarStart">
                     <!-- <a href="#" @click.prevent="goTo('Sessions')">All classes</a> -->
                     <router-link to="/classes" class="typeLink-actionBack">All classes</router-link>
                  </div>
                  <div class="Page-actionBarCenter">
                     <div class="Page-titlePill" v-if="typeof session.subject !== 'undefined'">{{ session.subject }}</div>
                  </div>
               </div>
            </div>
         </div>

         <div class="Grid s-1/2--desk middle">
            <div class="Grid-column center">
               <div class="typeLede mt++">{{ session.introduction }}</div>
               <div class="mt++" v-show="typeof equipment !== 'undefined' && session.equipment.length > 0">Before we get started can you make sure you have:</div>
            </div>

            <ul class="Grid-row s-2/3--lap mt">
               <li class="Grid-column mt+" :class="{'s-1/2--tab': equipment.length > 1}" v-for="(item, index) in equipment">
                  <div class="MediaFlex MediaFlex--middle">
                     <div class="MediaFlex-image">
                        <div class="icon icon--media" :class="item.icon"></div>
                     </div>
                     <div class="MediaFlex-body">
                        <div class="typeLabel">{{item.text}} <a v-if="typeof item.link !== 'undefined'" :href="item.link.url" target="_blank">{{ item.link.text }}</a></div>
                     </div>
                  </div>
               </li>
            </ul>
         </div>
      </div>

      <div class="Page-panel Page-panel--paint">
         <div class="Grid">
            <div class="Grid-row s-3/4--lap">
               <div class="aspectVideo aspectVideo--iframe">
                  <div class="aspectVideo-message" v-if="typeof session.iframe == 'undefined' && typeof session.type == 'undefined'">Sorry, no video has been added yet!</div>

                  <video-player class="aspectVideo-video" v-if="typeof session.video !== 'undefined'" :videoSource="session.video" :videoType="session.type" :scheduledFor="session.scheduledFor"></video-player>
               </div>

               <div class="mt++">
                  <vue-tabs>
                     <vue-tab name="Details" :selected="true">
                        <div class="typeCopy mw-38">{{ session.details }}</div>
                     </vue-tab>

                     <vue-tab name="Comments">
                        <session-comments :restrictCommentsProp="true" :sessionIndexProp="sessionIndex" :sessionIdProp="sessionId"></session-comments>
                     </vue-tab>

                     <vue-tab name="Notes" v-if="typeof session.notes !== 'undefined'">
                        <div class="typeCopy mw-38">Any extra information could go here maybe...</div>
                     </vue-tab>

                     <vue-tab name="Downloads" v-if="typeof session.worksheets !== 'undefined' && session.worksheets.length > 0">
                        <div class="typeCopy mw-38">
                           <h3>Worksheets and other downloads</h3>

                           <ul class="mt++">
                              <li class="" v-for="(item, index) in session.worksheets">
                                 {{item.label}} - <a href="#" target="_blank" class="typeLink" @click.prevent="downloadFile(item)">Download</a>
                              </li>
                           </ul>
                        </div>
                     </vue-tab>

                     <vue-tab name="Worksheets" v-if="typeof session.mathWorksheet !== 'undefined'">
                        <!-- <div class="typeCopy mw-38">Downloadable worksheets...</div> -->
                        <math-worksheet></math-worksheet>
                     </vue-tab>
                  </vue-tabs>
               </div>
            </div>
         </div>
      </div>
   </section>
</template>

<script>
   import Vue from "vue";
   import { mapState } from 'vuex';
   import VueTabs from './../components/vue-tabs.vue';
   import VueTab from './../components/vue-tab.vue';
   import MathWorksheet from './../components/math-worksheet.vue';

   import VideoPlayer from './../components/video-player.vue';
   import SessionComments from './../components/session-comments.vue';

   export default Vue.extend({
      components: {
         VueTabs,
         VueTab,
         MathWorksheet,
         VideoPlayer,
         SessionComments
      },

      data() {
         return {
            sessionId: '',
            sessionIndex: 0,

            equipment: [],
            equipmentList: {
               'Pen and Paper': {
                  icon: 'icon-document-edit',
                  text: 'A pen and paper'
               },
               'Calculator': {
                  icon: 'icon-calculator',
                  text: 'A calculator or',
                  link: {
                     text: 'use an online one',
                     url: 'https://www.online-calculator.com'
                  }
               },
               'Pencil': {
                  icon: 'icon-pen-angled',
                  text: 'A pencil',
               },
               'Thesaurus': {
                  icon: 'icon-book1',
                  text: 'A thesaurus (if you have one)',
               },
               'Dictionary': {
                  icon: 'icon-book1',
                  text: 'A dictionary (if you have one)',
               },
               'Plain paper': {
                  icon: 'icon-notebook6',
                  text: 'Dictionary (if you have one)',
               },
               'Coloured paper': {
                  icon: 'icon-note',
                  text: 'Coloured paper',
               },
               'Scissors': {
                  icon: 'icon-scissors',
                  text: 'A pair of scissors',
               },
               'Glue': {
                  icon: 'icon-flat-brush',
                  text: 'Some glue',
               },
               'Paint': {
                  icon: 'icon-brush',
                  text: 'Some paint',
               },
               'Coloured pens': {
                  icon: 'icon-pen-angled',
                  text: 'Coloured pens',
               },
               'Coloured pencils': {
                  icon: 'icon-pen-angled',
                  text: 'Coloured pencils',
               },
               'Coloured pens or pencils': {
                  icon: 'icon-pen-angled',
                  text: 'Coloured pens or pencils',
               },
               'Dancing feet!': {
                  icon: 'icon-sports-shoe',
                  text: 'Your dancing feet!',
               },
               'Ingredients': {
                  icon: 'icon-clipboard-list',
                  text: 'Ingredients',
               }
            }
         }
      },

      computed: {
         ...mapState({
            user: state => state.user,
            sessions: state => state.sessions,
         }),

         session() {
            return this.sessions[this.sessionIndex];
         },

         liveNotonAir() {
            return this.session.type == 'Live' && this.session.mux.status == 'idle' && this.isAvailable;
         }
      },

      watch: {
         session: {
            immediate: true,
            handler() {
               this.setSession();
            }
         }
      },

      methods: {
         setSession() {
            this.sessionId = this.$route.params.id;

            for (var i = 0; i < this.sessions.length; i++) {
               if (this.sessions[i].id == this.sessionId) {
                  this.sessionIndex = i;
                  break;
               }
            }

            this.formatEquipment();
         },

         downloadFile(item) {
            // console.log('dl', item.downloadUrl)
            var regex = '%2F(.*?)\\?alt';
            var match = item.downloadUrl.match(/%2F(.*?)\?alt/)
            var filename = match[1];
            // console.log('fn', match[1]);

            var xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = function(event) {
               var blob = xhr.response;
               let a = document.createElement("a");

               a.style = "display: none";
               document.body.appendChild(a);
               //Create a DOMString representing the blob and point the link element towards it
               let url = window.URL.createObjectURL(blob);
               a.href = url;
               a.download = filename;
               //programatically click the link to trigger the download
               a.click();
               //release the reference to the file by revoking the Object URL
               window.URL.revokeObjectURL(url);
            };
            xhr.open('GET', item.downloadUrl);
            xhr.send();

         },

         formatEquipment() {
            this.equipment = [];

            if (typeof this.session !== 'undefined' && typeof this.session.equipment !== 'undefined') {
               this.session.equipment.forEach((item, i) => {
                  var eq = {
                     icon: this.equipmentList[item].icon,
                     text: this.equipmentList[item].text
                  }

                  if (typeof this.equipmentList[item].link !== 'undefined') {
                     eq.link = {
                        text: this.equipmentList[item].link.text,
                        url: this.equipmentList[item].link.url
                     }
                  }

                  this.equipment.push(eq)
               });
            }
         }
      }
   });
</script>
