<template>
   <div class="Grid center" v-show="loading.isLoading">
      <div class="Grid-column">
         Loading...
      </div>
   </div>
</template>

<script>
   import Vue from "vue";
   import { mapState } from 'vuex';

   export default {
      data() {
         return {}
      },

      computed: {
         ...mapState({
            loading: state => state.loading,
            user: state => state.user,
            sessions: state => state.sessions,
         })
      },
   }
</script>
